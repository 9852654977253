import { api } from "../../api/stagingApi.js";

const getDashboardBySelections = async (
  idImpact = '',
  status = '',
  fund = '',
  demo = '',
  year = ''
) => {

  const resp = await api.get(
    `/files/getDashboardBySelections?idImpact=${idImpact}&status=${encodeURIComponent(
      status
    )}&fund=${fund}&year=${year}&demo=${demo}`
  );

  return resp;
};

export default getDashboardBySelections;
