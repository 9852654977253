export function handleAllCase() {
  this.linechartShow = true;
  this.stackedChartShow = false;
  this.fundPlaceholder = 'Show all';
  this.fundSelectboxDisabled = false;
  this.demoSelectboxDisabled = false;
  this.selectedFund = null;
  this.selectedDemo = null;
  this.selectedStatus = null;
  this.status = '- all,';
  this.fund = '';
  this.demo = '';
}

export function handleStatusCase() {
  const isSelectedStatusNull = this.selectedStatus === null;
  this.linechartShow = isSelectedStatusNull;
  this.stackedChartShow = !isSelectedStatusNull;
  this.fundPlaceholder = 'Show All';
  this.status = isSelectedStatusNull ? '' : `- ${this.selectedStatus}`;
  this.stackedBarchartOption.legend.display = false;
}

export function handleFundCase() {
  this.linechartShow = true;
  this.stackedChartShow = false;

  if (this.selectedFund === 'Show all') {
    this.selectedDemo = null;
    this.fundPlaceholder = 'Show All';
    this.selectedFund = null;
    this.selectedStatus = null;
    this.viewMode = 'All';
  }

  const isSelectedStatusNull = this.selectedStatus === null;
  this.status = isSelectedStatusNull ? '- all' : `- ${this.selectedStatus}`;

  this.demoSelectboxDisabled = false;
  this.selectedStatus ? false : true;
  this.selectedDemo ? false : true;
  this.fund = this.selectedFund ? `, ${this.selectedFund}` : '';
  this.demo = '';
}

export function handleDemoCase() {
  this.linechartShow = false;
  this.stackedChartShow = true;

  this.demoSelectboxDisabled = false;
  this.stackedBarchartOption.legend.display = true;
  this.demo = this.selectedDemo ? `, ${this.selectedDemo}` : '';
}
