export const formatStatusName = (name) => {
  if (!name) return '';
  return name.replace('%2', ' ').replace('%3', '.').replace('e.Fg%3F', 'e.g.');
};

export const formatNameInLineChart = (nameCheckbox) => {
  if (!nameCheckbox) return '';
  return nameCheckbox
    .replace('%2', ' ')
    .replace('%3', '.')
    .replace('e.Fg%3F', 'e.g.');
};
