import { getLineChartColorScheme as colorScheme } from '../../data/colour-scheme';

export const chartDataObjFunc = (dataset, labels, extraData) => {
  const lineChartData = [];
  let chartDataObj = {};

  const labels_decoded = Object.keys(dataset[0]).map((item) =>
    decodeURIComponent(item)
  );

  let labels_shorted = [];
  if (extraData.isNumeric == false) {
    labels_shorted = labels_decoded.map((lbl) => {
      if (lbl.length > 10) {
        return lbl.substring(0, 10) + '...';
      } else {
        return lbl;
      }
    });
  } else {
    labels_shorted = labels_decoded;
  }

  for (let i = 0; i < dataset.length; i++) {
    const color = colorScheme(i);
    const dataTem = Object.values(dataset[i]);
    dataTem.unshift(0);
    const lineChartDataSet = {
      chartType: 'line',
      label: labels[i],
      babelExtra: labels[i],
      backgroundColor: 'transparent',
      borderColor: color,
      data: dataTem,
      datasets: dataTem, // reduce redundancy
      labels: labels_shorted,
      pointRadius: 6,
      borderWidth: 1.5,
      pointBackgroundColor: '#FFFFFF',
      lineTension: 0,
      pointHoverRadius: 8,
      pointStyle: 'round',
    };
    lineChartData.push(lineChartDataSet);
  }

  const nameGraphics = labels_shorted;
  nameGraphics.unshift('0');

  chartDataObj = {
    labels: nameGraphics,
    datasets: lineChartData,
  };

  return chartDataObj;
};
