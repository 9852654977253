
<template>
  <div class="container">
    <div class="table-left">
      <table>
        <tr>
          <th>
            <div class="title-ellipsis" id="tooltip-target-title">
              <template v-if="!isDataDummy">
                {{ headerTitles.column1 }}
              </template>
              <template v-else>
                <!-- <div class="indication" @click="focusInputField('inputColumn1')">
                  <p>Tap to edit</p>
                </div> -->
                <input v-if="isDataDummy" ref="inputColumn1" class="input-tenants" v-model="headerTitles.column1" />
              </template>
            </div>
            
            <b-tooltip target="tooltip-target-title" triggers="hover">
              {{ headerTitles.column1 }}
            </b-tooltip>
          </th>
        </tr>
        <tr v-for="dataObj in tableData" :key="dataObj.propId">
          <td>
            <div
              class="title-ellipsis"
              :class="dataObj.cssId"
              :id="`tooltip-target-${dataObj.propId}`"
            >
              {{ dataObj.name }}
            </div>
            <b-tooltip
              :target="`tooltip-target-${dataObj.propId}`"
              triggers="hover"
            >
              {{ dataObj.name }}
            </b-tooltip>
          </td>
        </tr>
        <tr>
          <th>Total</th>
        </tr>
      </table>
    </div>
    <div class="table-center">
      <table>
        <tr>
          <th v-for="dataTitle in headersCentral" :key="dataTitle">
            <div class="title-ellipsis" :id="`tooltip-target-${dataTitle}`">
              {{ dataTitle }}
            </div>
            <b-tooltip :target="`tooltip-target-${dataTitle}`" triggers="hover">
              {{ dataTitle }}
            </b-tooltip>
          </th>
        </tr>
        <tr v-for="detailRow in dataTableCenter.center" :key="detailRow.propId" v-bind="rowStyle(detailRow, 'row')">
          <td v-for="(detailCol, index) in detailRow.tenants" :key="index" >
            <div> 
              {{ detailCol }}
            </div>
          </td>
        </tr>
        <tr>
          <td
            v-for="(detailTotal, index) in dataTableCenter.total"
            :key="index"
          >
            <div class="title-ellipsis table-total">
              {{ detailTotal }}
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="table-right">
      <table>
        <tr v-if="!isDataDummy">
          <th>
            <div class="title-ellipsis" id="tooltip-target-tableColumn10">
              {{ headerTitles.column10 }}
            </div>
            <b-tooltip target="tooltip-target-tableColumn10" triggers="hover">
              {{ headerTitles.column10 }}
            </b-tooltip>
          </th>
          <th>
            <div class="title-ellipsis" id="tooltip-target-tableColumn11">
              {{ headerTitles.column11 }}
            </div>
            <b-tooltip target="tooltip-target-tableColumn11" triggers="hover">
              {{ headerTitles.column11 }}
            </b-tooltip>
          </th>
          <th>
            <div class="title-ellipsis" id="tooltip-target-tableColumn12">
              {{ headerTitles.column12 }}
            </div>
            <b-tooltip target="tooltip-target-tableColumn12" triggers="hover">
              {{ headerTitles.column12 }}
            </b-tooltip>
          </th>
        </tr>
        <tr v-else>
          <th>
            <div class="title-ellipsis" id="tooltip-target-tableColumn10">
              <!-- <div class="indication" @click="focusInputField('inputColumn10')">
                <p>Tap to edit</p>
              </div> -->
              <input ref="inputColumn10" class="input-tenants" v-model="headerTitles.column10" />
            </div>
            <b-tooltip target="tooltip-target-tableColumn10" triggers="hover">
              {{ headerTitles.column10 }}
            </b-tooltip>
          </th>
          <th>
            <div class="title-ellipsis" id="tooltip-target-tableColumn11" @click="focusInputField('inputColumn11')">
              <input ref="inputColumn11" class="input-tenants" v-model="headerTitles.column11" />
            </div>
            <b-tooltip target="tooltip-target-tableColumn11" triggers="hover">
              {{ headerTitles.column11 }}
            </b-tooltip>
          </th>
          <th>
            <div class="title-ellipsis" id="tooltip-target-tableColumn12" @click="focusInputField('inputColumn12')">
              <!-- <div class="indication">
                <p>Tap to edit</p>
              </div> -->
              <input ref="inputColumn12" class="input-tenants" v-model="headerTitles.column12" />
            </div>
            <b-tooltip target="tooltip-target-tableColumn12" triggers="hover">
              {{ headerTitles.column12 }}
            </b-tooltip>
          </th>
        </tr>
        <!-- v-bind="rowStyle(detailRow, 'row')" -->
        <tr v-for="detailRow in dataTableCenter.final" :key="detailRow.propId">
          <td>
            <div class="title-ellipsis">
              {{ detailRow.column10 }}
            </div>
          </td>
          <td>
            <!--<demo-doughnut-chart 
              class="chart" 
              :tenant="detailRow.column11"
              :colorIndex="detailRow.colorIndex"
              :cssId="detailRow.cssId"
            />-->
            <div class="title-ellipsis">
              {{ detailRow.column11 != null && detailRow.column11 != NaN ?   detailRow.column11 : 0 }}
              /
              {{ detailRow.column10 }}
            </div>
          </td>
          <td>
            <!--<demo-doughnut-chart 
              class="chart" 
              :tenant="detailRow.pollo"
              :colorIndex="detailRow.colorIndex"
              :cssId="detailRow.cssId"
            />-->
            {{ detailRow.pollo != null && detailRow.pollo != NaN ?  detailRow.pollo : 0 }}
          </td>
        </tr>
        <tr>
          <td class="table-total">{{ dataTableCenter.totalFinal }}</td>
          <td></td>
          <td></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
//import DemoDoughnutChart from '../components/Chart/DemoDoughnutChart'
import { getLineChartColorScheme } from '../data/colour-scheme';
export default {
  components: {
    //DemoDoughnutChart
  },
  props: {
    tableData: {
      type: Array,
    },
    checkedItems: {
      type: Array,
    },
    status: {
      type: Boolean,
    },
    headerTitles: {
      type: Object,
    },
    isDataDummy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [],
      headersCentral: [],
      rowTotal: {},
      topic: {
        cssId: "pollo",
        colorIndex: 1,
      },
      tenant: 45,
    };
  },
  computed: {
    dataTableCenter() {
      const newDataCenter = [];
      const newDataFinal = [];
      const newDataTotal = [];
      let sumaTotalFinal = 0;
      for (const item of this.tableData) {
        newDataCenter.push({
          colorIndex: item.colorIndex,
          cssId: item.cssId,
          name: item.name,
          propId: item.propId,
          totalTenants: item.totalTenants,
          type: item.type,
          vForId: item.vForId,
          months: item.monthlyData.months,
          tenants: item.monthlyData.tenants.slice(0, -2),
        });
        /*let column11 = 0
        if (!isNaN(item.monthlyData.tenants[item.monthlyData.months.length + 1].replaceAll("%",""))) {
          column11 = parseFloat(item.monthlyData.tenants[item.monthlyData.months.length + 1].replaceAll("%",""))
        }*/
        
        sumaTotalFinal =
          sumaTotalFinal +
          parseInt(item.monthlyData.tenants[item.monthlyData.months.length]);
        newDataFinal.push({
          colorIndex: item.colorIndex,
          propId: item.propId,
          totalTenants: item.totalTenants,
          column10: item.monthlyData.tenants[item.monthlyData.months.length],
          column11: item.positiveMoveOn != null ? parseInt(item.positiveMoveOn): 0,
          pollo: item.positivesMoveOnAvg != null ? ~~(item.positivesMoveOnAvg/item.positiveMoveOn) :0
        });
        for (let index = 0; index < item.monthlyData.months.length; index++) {
          //const element = item.monthlyData.months[index]
          const elem_value = item.monthlyData.tenants[index];
          if (newDataTotal[index]) {
            newDataTotal[index] = newDataTotal[index] + parseInt(elem_value);
          } else {
            newDataTotal[index] = parseInt(elem_value);
          }
        }
      }
      return {
        center: newDataCenter,
        final: newDataFinal,
        total: newDataTotal,
        totalFinal: sumaTotalFinal,
      };
    },
  },
  methods: {
    focusInputField(fieldName) {
      this.$refs[fieldName].focus();
    },
    rowStyle(item, type) {
      if (!item || type !== 'row') return
      if (this.checkedItems.includes(item.name)) {
        return { style: "color: " + getLineChartColorScheme(item.colorIndex) + ";", };
      } else {
        return {};
      }
    },
    showTitulos: function (/*val*/) {
      const headers_table_central = [];
      if(this.tableData.length === 0) return;
      const titulos = this.tableData[0].monthlyData.months;
      for (const [/*index*/, title] of titulos.entries()) {
        headers_table_central.push(decodeURIComponent(title));
      // console.log(index)
      }
      this.headersCentral = headers_table_central;
    },
  },
  watch: {
    status: {
      immediate: true,
      handler(val) {
        this.showTitulos(val);
      },
    },
    headerTitles: function () {
      this.showTitulos(this.status);
    },
  },
};
</script>

<style scoped>
.celda {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-total {
  font-weight: bold;
}
.container {
  position: relative;
  display: flex;
  margin: unset !important;
}
.table-center {
  overflow-x: auto;
  flex-grow: 1;
}
.table-right table td:first-child,
.table-right table th:first-child{
  border-left: 1px solid #d8d8d8;
}
.table-left table td:first-child,
.table-left table th:first-child{
  border-right: 1px solid #d8d8d8;
}

table tr:first-child {
  border-top: none;
}

table tr {
  height: 100px;
  border-top: 1px solid #dee2e6;
}

table {
  width: 100%;
  font-size: 1.5rem;
  color: #717171;
  font-weight: normal;
}

table th .title-ellipsis {
  word-break: break-word;
}
table th .title-ellipsis,
table td .title-ellipsis {
  white-space: break-spaces;
  width: 105px;
  padding: 0px 5px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-tenants {
  border: none;
  font-size: 1.5rem;
  border-bottom: 2px solid var(--color-purple);
  background-color: unset;
}

.input-tenants:focus {
  outline: none;
}
</style>
