<template>
  <div class="table-responsive">
    <table id="table-content" class="table">
      <thead class="thead w-100">
        <tr class="w-100">
          <th class="first-th" scope="col">{{tableName}}<img src="../../src/assets/Sorting.svg" v-on:click="sortTable(name)" class="sort-button"/></th>
          <th class="second-th" scope="col" v-for="month in monthlyColumn" :key="month">{{month}}</th>
          <th class="third-th" scope="col">Total tenancies<img src="../../src/assets/Sorting.svg" v-on:click="sortTable(totalTenants)" class="sort-button"/></th>
          <th class="fourth-th" scope="col">Difference in 12 Months <img src="../../src/assets/Sorting.svg" v-on:click="sortTableDatabyDifference" class="sort-button"/></th>
        </tr>
      </thead>
      <tbody class="tbody-container">
        <tr class="w-100" v-for="data in tableData" v-bind:key="data.name">
          <th v-bind:class="data.cssId" id="country-name" class="border-right">
            {{data.name.replace('%2', ' ')}}
          </th>
          <td scope="row" class="monthly-data" v-for="(month, index) in data.monthlyData.tenants" v-bind:key="index" v-bind:style="borderStyle(index)">
            {{month}}
          </td>
        </tr>
      </tbody>

    </table>
  </div>
</template>

<script>
import { getMonthlyColumn } from '../data/data-handler'
export default {
  props: {
    tableData: {
      type: Array
    }},
  data () {
    return {
      sortedByDifference: false,
      sorted: false,
      tableName: 'Name',
      monthlyColumn: getMonthlyColumn(),
      name: '',
      totalTenants: ''
    }
  },
  methods: {
    sortTable () {
      if (this.sorted === false) {
        const unsorted = this.tableData
        unsorted.sort((a, b) => (a.columnName > b.columnName) ? 1 : -1)
        this.sorted = true
      } else if (this.sorted === true) {
        const sorted = this.tableData
        sorted.sort((a, b) => (a.columnName < b.columnName) ? 1 : -1)
        this.sorted = false
      }
    },
    sortTableDatabyDifference () {
      if (this.sortedByDifference === false) {
        const unsorted = this.tableData
        unsorted.sort((a, b) => (parseInt(a.monthlyData.tenants[13].slice(0, -1), 10) > parseInt(b.monthlyData.tenants[13].slice(0, -1), 10)) ? 1 : -1)
        this.sortedByDifference = true
      } else if (this.sortedByDifference === true) {
        const sorted = this.tableData
        sorted.sort((a, b) => (parseInt(a.monthlyData.tenants[13].slice(0, -1), 10) < parseInt(b.monthlyData.tenants[13].slice(0, -1), 10)) ? 1 : -1)
        this.sortedByDifference = false
      }
    },
    borderStyle (index) {
      if (index === 11) {
        return 'border-right:1px solid #D8D8D8;'
      }
    }
  },
  watch: {
    tableData () {
      this.tableName = this.tableData[0].type
    }
  }
}
</script>

<style src="../assets/styles/components/Table.css"></style>