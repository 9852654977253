<template>
  <div>
      <section id="projectdetails">
        <BRow>
          <BCol :lg="8" class="page-title" v-if="showBackArrow">
            <div align="left" class="back">
              <router-link to="/">
                <img src="../../../src/assets/BackArrow.svg" alt="back-arrow" />
                Back
              </router-link>
            </div>

            <h1 class="title loader-animation"></h1>
          </BCol>
          <BCol :lg="4" class="progress-summary">
            <div class="doughnut-1 loader-animation"></div>

            <div class="doughnut-2 loader-animation"></div>
          </BCol>
        </BRow>
      </section>
      <section class="projectdetails-select-area-projectdetails">
        <BRow :gutter="12" class="loader-animation">
          <BCol :lg="1"
            ><h3 class="projectdetails-select-country loader-animation"></h3
          ></BCol>
          <BCol :lg="2.5" class="projectdetails-select-box loader-animation">
          </BCol>
          <BCol :lg="1"
            ><h3 class="projectdetails-select-camp loader-animation"></h3
          ></BCol>
          <BCol :lg="3.6" class="projectdetails-select-box loader-animation">
          </BCol>
          <BCol :lg="1.4"
            ><h3 class="projectdetails-select-school loader-animation"></h3
          ></BCol>
          <BCol :lg="2.5" class="projectdetails-select-box loader-animation">
          </BCol>
        </BRow>
      </section>
      <section :gutter="12" class="chart-title-area">
        <BRow class="chart-title">
          <BCol :lg="8" :xs="6"
            ><h2 class="projectdetails-sub-title loader-animation"></h2
          ></BCol>
          <BCol class="chart-summary" :lg="4" :xs="6">
            <div class="total-tenants loader-animation">
              <span
                ><h1></h1>
                <h2></h2
              ></span>
              <h3
                class="loader-animation font-source-sans"
              ></h3>
            </div>
            <div class="growth-rate loader-animation">
              <h3
                class="loader-animation font-source-sans"
              ></h3>
            </div>
          </BCol>
        </BRow>
      </section>
      <section id="chart-area" class="loader-animation">
        <BRow :gutter="12" class="chart-main">
          <BCol
            :lg="8"
            class="line-chart-area loader-animation"
          >
            <div class="w-300"></div>
            <BCol
              :lg="4"
              :xs="4"
              class="year-select-box loader-animation"
            ></BCol>
          </BCol>
        </BRow>
        <Table
          :calculationTable="calculationTable"
          :tableData="tableData"
          v-if="linechartShow === true && viewMode !== 'Fund'"
        ></Table>

        <Table
          :calculationTable="calculationTable"
          :tableData="tableDataByStatus"
          v-if="
            (stackedChartShow === true && viewMode === 'Status') ||
            (linechartShow === true && viewMode === 'Fund')
          "
        ></Table>
        <TableForTopic
          :TopicTableData="TopicTableData"
          :tableDataCalculos="tableDataCalculos"
          :eselectedYear="eselectedYear"
          v-if="viewMode === 'Demo'"
        ></TableForTopic>
      </section>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue';
import Table from "../../components/Table.vue"
import TableForTopic from "../../components/TableforTopic";

export default {
    name: 'Preloader',
    components: {
        Table,
        TableForTopic,
        BCol,
        BRow,
    },
    props: {
        TopicTableData: {
            type: Array
        },
        tableData: {
            type: Array
        },
        calculationTable: {
            type: Object
        },
        tableDataByStatus: {
            type: Array
        },
        stackedChartShow: {
            type: Boolean
        },
        viewMode: {
            type: String
        },
        linechartShow: {
            type: Boolean
        },
        tableDataCalculos: {
          type: Object 
        },
        eselectedYear: {
          type: String
        },
        showBackArrow: {
            type: Boolean,
            default: true,
        },
    },
}
</script>

<style scoped>
.font-source-sans {
  font-family: 'Source Sans Pro'
}

.line-chart-area {
  padding-top: 10px !important
}

.w-300 {
  height: 300px
}
</style>
