import { getLineChartColorScheme } from '../../../data/colour-scheme';
import {
  calcSum,
  compareDataByYear,
  diffeQtyPeopleInYears,
  getTableData,
} from '../../../data/data-handler';
import caseThreeSelections from '../caseThreeSelections';
import { processCheckedItemsStatus } from '../colors/processCheckedItems';
import { filterTopics } from '../../../helpers/projectDetails/filterTopics';

export async function handleStatusCaseDashboard() {
  let tenants = this.tenants;
  let prevYearTenants = this.prevYearTenants;
  let totalCurrTenants = this.totalCurrTenants;
  let totalPrevTenants = this.totalPrevTenants;
  let selectedStatus = this.selectedStatus;
  let selectedFund = this.selectedFund;
  let selectedDemo = this.selectedDemo;
  let displayingDataUpTo = this.displayingDataUpTo;

  prevYearTenants.tenants = [];
  if (
    selectedStatus !== null &&
    (selectedFund === null || selectedFund === 'Show all') &&
    selectedDemo === null
  ) {
    this.tempo1 = [];
    this.tempo2 = [];

    if (this.checkedItems.length > 0) {
      processCheckedItemsStatus.call(this);

      this.stackedBarChartData = this.filterChartDataDos(
        this.SchartData,
        this.checkedItems
      );

      this.updateColors(this.viewMode, getLineChartColorScheme);
      return;
    }

    let tableTenants = {};
    let summaryboxTenants = {};
    let prevsummaryboxTenants = {};
    // Only status
    this.preloader = true;

    // Si no hay checkbox(es) seleccionados
    if (this.checkedItems.length === 0) {
      const status = this.selectedStatus;
      const idImpact = this.impactId;
      const year = this.selectedYear;

      const prevYearExists = displayingDataUpTo.some(
        (item) => item.year === (year - 1).toString()
      );

      const data = await caseThreeSelections(idImpact, status, '', '', year);
      tableTenants = data;
      summaryboxTenants = data;

      prevsummaryboxTenants.tenants = {};
      if (prevYearExists) {
        const dataPrevYear = await caseThreeSelections(
          idImpact,
          status,
          '',
          '',
          year - 1
        );
        prevYearTenants = dataPrevYear;
        prevsummaryboxTenants = prevYearTenants;
      }

      this.summaryBoxData = filterTopics(
        getTableData('Fund', summaryboxTenants, prevsummaryboxTenants)
      );

      this.tempo1 = [];
      this.tempo2 = [];

      processCheckedItemsStatus.call(this);

      tenants = tableTenants;
      this.stackedBarChartData = this.SchartData;

      this.tableDataByStatus = getTableData(
        'Status',
        tableTenants,
        prevYearTenants
      );

      totalCurrTenants = tenants?.tenants.flatMap((el) => Object.values(el));
      totalPrevTenants = prevYearTenants?.tenants?.flatMap((el) =>
        Object.values(el)
      );

      this.totalTenants = calcSum(totalCurrTenants);

      this.totalDifferenceTenantsByYears = diffeQtyPeopleInYears(
        totalPrevTenants,
        totalCurrTenants
      );

      // porcentaje (last 12 months)
      this.growthRate = compareDataByYear(totalPrevTenants, totalCurrTenants);
    }

    this.updateColors(this.viewMode, getLineChartColorScheme);
    this.preloader = false;
  }
}
