const buildLabelsAndTenants = (response, year) => {
  if(!year) return;
  const keyLabels = Object.keys(response);
  let allMonthlyDataByYears = Object.values(response);
  let existingTopicsInYear = {};
  let positiveMoveOn = {}
  let positiveMoveOnAvg = {}
  keyLabels.forEach((el, index) => {
    const monthlyData = allMonthlyDataByYears[index].values[year];
    const positiveMove = allMonthlyDataByYears[index].values[`${year}-moveOn`];
    const positiveMoveAvg = allMonthlyDataByYears[index].values[`${year}-moveOnAvg`];
    existingTopicsInYear[el] = monthlyData || 0;
    positiveMoveOn[el] = positiveMove
    positiveMoveOnAvg[el] = positiveMoveAvg
  });

  const valueTenants = Object.values(existingTopicsInYear);
  const valuePositiveMoveOn = Object.values(positiveMoveOn);
  const valuePositiveMoveOnAvg = Object.values(positiveMoveOnAvg);

  return { labels: keyLabels, tenants: valueTenants, positives: valuePositiveMoveOn, positivesAvg: valuePositiveMoveOnAvg };
};

export default buildLabelsAndTenants;
