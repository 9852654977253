import { getMonthlyColumn } from "../../data/data-handler";

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        gridLines: {
          color: "#ffffff",
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
  legend: {
    labels: {
      boxWidth: 10,
      fontSize: 14,
      padding: 20,
      usePointStyle: true,
    },
  },
  tooltips: {
    callbacks: {
      title: function (tooltipItem, data) {
        var tooltipLabel =
          data.datasets[tooltipItem[0].datasetIndex].label;
        if (data.datasets[0].label == 'All') {
          tooltipLabel = "All status | All funds";
        }
        return tooltipLabel;
      },
      label: function (tooltipItem) {
        return tooltipItem.value + " Tenants";
      },
    },
  },
};

export const stackedBarchartOption = {
  responsive: true,
  maintainAspectRatio: false,

  scales: {
    xAxes: [
      {
        stacked: true,
        gridLines: {
          color: '#ffffff',
        },
        ticks: {
          callback: function (value, index) {
            if (value.length < 3) {
              value = getMonthlyColumn()[index];
              return value;
            } else {
              return value;
            }
          },
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        suggestedMin: true,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
  legend: {
    labels: {
      generateLabels: function () {
        return null;
      },
    },
  },
  display: true,
  tooltips: {
    enabled: true,
    callbacks: {
      title: function (tooltipItem, data) {
        var tooltipLabel = data.datasets[tooltipItem[0].datasetIndex].label;
        if (data.datasets[0].label == 'All') {
          tooltipLabel = 'All status | All funds';
        }
        return tooltipLabel;
      },
      label: function (tooltipItem) {
        return tooltipItem.label + ' : ' + tooltipItem.value + ' Tenants';
      },
    },
  },
};

export const barchartOption = {
  animation: {
    duration: 0,
  },
  scales: {
    xAxes: [
      {
        display: false,
      },
    ],
    yAxes: [
      {
        display: false,
      },
    ],
  },
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      title: function (tooltipItem) {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        return months[tooltipItem[0].index];
      },
    },
  },
};
