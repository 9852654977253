<script>
export default {
    name: 'SaveDashboardButton',
    props: {
        saveDashboard: {
            type: Function,
            default: () => { },
        },
        titleBtn: {
            type: String,
            default: 'Save dashboard'
        }
    }
}
</script>

<template>
    <div data-testid="btn-save-dashboard" class="saveDashboard" @click="saveDashboard">
        <div class="arrow-save-dashboard-container">
            <img src="../../assets/green-arrow.svg" alt="arrow-svg">
        </div>
        <div class="text-save-dashboard-container">
            <p data-testid="btn-title" class="text-save-dashboard">{{ titleBtn }}</p>
        </div>
    </div>
</template>

<style>
.saveDashboard {
    align-content: center;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1.9px solid #bed23f;
    cursor: pointer;
    display: -webkit-inline-box;
    padding: 0.8rem 1.4rem;
    gap: 0.6rem;

}

.saveDashboard p {
    font-size: 1.2rem !important;
    color: #bed23f !important;
    margin: 0px;
    font-weight: 500;
    width: 100%;
}
</style>