/**
 * It performs filtering and data updating related to the "demographics"
 *  chart and tables, and also carries out a color update
 */

import {
  getLineChartColorSchemeNoIdex,
  getTopicColorSchme,
} from '../../../data/colour-scheme';
import {
  getStackedBarChartDataDemo,
  getTableData,
} from '../../../data/data-handler';
import { filterTopics } from '../../../helpers/projectDetails/filterTopics';

export function processCheckedItemsStatus() {
  const blackListFundOpts = [
    'RLPF 1',
    'RLPF 2',
    'NHPF - Oxford',
    'NHPF - Bristol',
    'NHPF - Milton Keynes',
    'NHPF - Cherwell',
    'No information',
  ];

  this.summaryBoxData.forEach((data) => {
    if (data.name === 'No') {
      data.name = 'No ';
      data.cssId = 'no ';
      data.propId = 'No ';
      data.vForId = 'No ';
    }
  });

  if (this.secondDropdownOption !== 'Fund') {
    this.summaryBoxData = this.summaryBoxData.filter(
      (item) => !blackListFundOpts.includes(item.name)
    );
  }
  this.summaryBoxData.map((data) => {
    this.tempo1.push(data.name);
    this.tempo2.push(data.totalTenants);
  });

  this.SchartData = {
    labels: this.tempo1,
    datasets: [
      {
        label: this.selectedStatus + ' | ' + 'All funds',
        babelExtra: this.tempo1,
        backgroundColor: getLineChartColorSchemeNoIdex(),
        data: this.tempo2,
        labels: this.tempo1,
        barThickness: 20,
      },
    ],
  };
}

export function processCheckedItemsDemos() {
  const selectedStatus = this.selectedStatus;
  const selectedFund = this.selectedFund;
  const selectedDemo = this.selectedDemo;

  this.stackedBarChartData = this.filterChartDataDemo(
    getStackedBarChartDataDemo(this.tenants, getTopicColorSchme, {
      selectedStatus,
      selectedFund,
      selectedDemo,
      isNumeric: this.isNumeric,
    }),
    this.checkedItems
  );

  this.summaryBoxData = filterTopics(
    getTableData('Topics', this.tenants, this.prevYearTenants)
  );

  this.updateColors(this.viewMode, getTopicColorSchme);
}
