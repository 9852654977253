<template>
  <div class="container" v-if="dataTableCenter">
    <div class="table-left">
      <table>
        <tr>
          <th>
            <div class="title-ellipsis" id="tooltip-target-title">
              {{ status.name }}
            </div>
            <b-tooltip target="tooltip-target-title" triggers="hover">
              {{ status.name }}
            </b-tooltip>
          </th>
        </tr>
        <tr v-for="dataObj in tableData" :key="dataObj.propId">
          <td>
            <div
              class="title-ellipsis"
              :class="dataObj.cssId"
              :id="`tooltip-target-${dataObj.propId}`"
            >
              {{ dataObj.name }}
            </div>
            <b-tooltip
              :target="`tooltip-target-${dataObj.propId}`"
              triggers="hover"
            >
              {{ dataObj.name }}
            </b-tooltip>
          </td>
        </tr>
        <tr>
          <th>Total</th>
        </tr>
      </table>
    </div>
    <div class="table-center">
      <table>
        <tr>
          <th v-for="dataTitle in headersCentral" :key="dataTitle">
            <div class="title-ellipsis" :id="`tooltip-target-${dataTitle}`">
              {{ dataTitle }}
            </div>
            <b-tooltip :target="`tooltip-target-${dataTitle}`" triggers="hover">
              {{ dataTitle }}
            </b-tooltip>
          </th>
        </tr>
        <!-- <div v-if="detailRow"> -->

          <tr v-for="detailRow in dataTableCenter.center" :key="detailRow.propId" >
            <td v-for="(detailCol, index) in detailRow.tenants" :key="index">
              <div class="title-ellipsis" :class="detailRow.cssId"> 
                {{ detailCol }}
              </div>
            </td>
          </tr>
        <!-- </div> -->
        <tr>
          <td
            v-for="(detailTotal, index) in dataTableCenter.total"
            :key="index"
          >
            <div class="title-ellipsis table-total">
              {{ detailTotal }}
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="table-right">
      <table>
        <tr>
          <th>
            <div class="title-ellipsis" id="tooltip-target-tableColumn10">
              {{ headerTitles.column10 }}
            </div>
            <b-tooltip target="tooltip-target-tableColumn10" triggers="hover">
              {{ headerTitles.column10 }}
            </b-tooltip>
          </th>
          <th>
            <div class="title-ellipsis" id="tooltip-target-tableColumn11">
              {{ headerTitles.column11 }}
            </div>
            <b-tooltip target="tooltip-target-tableColumn11" triggers="hover">
              {{ headerTitles.column11 }}
            </b-tooltip>
          </th>
          <th>
            <div class="title-ellipsis" id="tooltip-target-tableColumn12">
              {{ headerTitles.column12 }}
            </div>
            <b-tooltip target="tooltip-target-tableColumn12" triggers="hover">
              {{ headerTitles.column12 }}
            </b-tooltip>
          </th>
        </tr>
        <tr v-for="detailRow in dataTableCenter.final" :key="detailRow.propId">
          <td>
            <div class="title-ellipsis">
              {{ detailRow.column10 }}
            </div>
          </td>
          <td>
            <!--<demo-doughnut-chart 
              class="chart" 
              :tenant="detailRow.column11"
              :colorIndex="detailRow.colorIndex"
              :cssId="detailRow.cssId"
            />-->
            {{ detailRow.column11 != null && detailRow.column11 != NaN ? (detailRow.column11 >= detailRow.column10 ? 0 :  detailRow.column11) : 0 }}
              /
              {{ detailRow.column10 }}
          </td>
          <td>
            <!--<demo-doughnut-chart 
              class="chart" 
              :tenant="detailRow.column12"
              :colorIndex="detailRow.colorIndex"
              :cssId="detailRow.cssId"
            />-->
            {{ detailRow.column12 != null && detailRow.column12 != NaN ? detailRow.column12 : 0 }}
          </td>
        </tr>
        <tr>
          <td class="table-total">{{ dataTableCenter.totalFinal }}</td>
          <td></td>
          <td></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
//import DemoDoughnutChart from '../components/Chart/DemoDoughnutChart'
export default {
  name: "TableforTopic",
  components: {
    //DemoDoughnutChart
  },
  props: {
    tableData: {
      type: Array,
    },
    checkedItems: {
      type: Array,
    },
    status: {
      type: Object,
    },
    headerTitles: {
      type: Object,
    },
  },
  data() {
    return {
      fields: [],
      headersCentral: [],
      rowTotal: {},
      topic: {
        cssId: "pollo",
        colorIndex: 1,
      },
      tenant: 45,
    };
  },
  computed: {
    dataTableCenter() {
      const newDataCenter = [];
      const newDataFinal = [];
      const newDataTotal = [];
      let sumaTotalFinal = 0;
      if(!this.tableData) return;
      for (const item of this.tableData) {
        newDataCenter.push({
          propId: item.propId,
          cssId: item.cssId,
          months: item.monthlyData.months,
          tenants: item.monthlyData.tenants.slice(0, -3),
        });
        let column12 = 0
        if (!isNaN(item.monthlyData.tenants[item.monthlyData.months.length + 2].replaceAll("%",""))) {
          column12 = parseFloat(item.monthlyData.tenants[item.monthlyData.months.length + 2].replaceAll("%",""))
          if (column12 < 0) {
            column12 = 0
          }
        }
        // console.log("column12", column12)
        newDataFinal.push({
          colorIndex: item.colorIndex,
          propId: item.propId,
          cssId: item.cssId,
          column10: item.monthlyData.tenants[item.monthlyData.months.length],
          column11:
            item.monthlyData.tenants[item.monthlyData.months.length + 1],
          column12: column12,
        });
        sumaTotalFinal =
          sumaTotalFinal +
          parseInt(item.monthlyData.tenants[item.monthlyData.months.length]);
        for (let index = 0; index < item.monthlyData.months.length; index++) {
          //const element = item.monthlyData.months[index];
          const elem_value = item.monthlyData.tenants[index];
          if (newDataTotal[index]) {
            newDataTotal[index] = newDataTotal[index] + parseInt(elem_value);
          } else {
            newDataTotal[index] = parseInt(elem_value);
          }
        }
      }
      return {
        center: newDataCenter,
        final: newDataFinal,
        total: newDataTotal,
        totalFinal: sumaTotalFinal,
      };
    },
  },
  methods: {
    calculateTotal: function (tenants) {
      let totales = 0;
      for (const individual_details of tenants) {
        if (!isNaN(individual_details)) {
          totales = totales + individual_details;
        } else {
          totales += 0;
        }
      }
      return totales;
    },
    showTitulos: function (/*val*/) {
      const headers_table_central = [];
      if(this.tableData.length === 0) return;
      const titulos = this.tableData[0].monthlyData.months;
      for (const [/*index*/, title] of titulos.entries()) {
        headers_table_central.push(decodeURIComponent(title));
      // console.log(index)
      }
      this.headersCentral = headers_table_central;
    },
  },
  watch: {
    status: {
      immediate: true,
      handler(val) {
        if(!val) return;
        this.showTitulos(val);
      },
    },
    tableData: function () {
      this.showTitulos(this.status);
    },
    headerTitles: function () {
      this.showTitulos(this.status);
    },
  },
};
</script>

<style scoped>
.celda {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-total {
  font-weight: bold;
}
.container {
  position: relative;
  display: flex;
}
.table-center {
  overflow-x: auto;
  flex-grow: 1;
}
.table-right table td:first-child,
.table-right table th:first-child{
  border-left: 1px solid #d8d8d8;
}
.table-left table td:first-child,
.table-left table th:first-child{
  border-right: 1px solid #d8d8d8;
}

table tr:first-child {
  border-top: none;
}

table tr {
  height: 60px;
  border-top: 1px solid #dee2e6;
}

table {
  width: 100%;
  font-size: 1.5rem;
  color: #717171;
  font-weight: normal;
}

table th .title-ellipsis {
  word-break: break-word;
}
table th .title-ellipsis,
table td .title-ellipsis {
  white-space: break-spaces;
  width: 105px;
  padding: 0px 5px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

