<template>
    <div>
        <div class="summary-wrapper" v-for="status in summaryBoxData" v-bind:key="status.vForId" :value="status.vForId">
            <div class="text-container">
                <input type="checkbox" v-bind:class="status.cssId" v-bind:id="status.cssId" v-bind:key="status.vForId"
                    :value="status.vForId" @click="$emit('check', status.vForId)" class="d-none" />
                <label v-bind:class="status.cssId" v-bind:for="status.cssId" class="mt-4">
                    <div v-bind:class="status.cssId" v-bind:for="status.cssId">
                        <span v-bind:class="status.cssId" v-bind:for="status.cssId" class="v-text">V</span>
                    </div>
                </label>
                <div class="summary-text" v-bind:class="status.cssId" v-bind:for="status.cssId">
                    <div v-bind:class="status.cssId" v-bind:for="status.cssId" class="box-checkbox-info">
                        <div class="bar-container">
                            <h1 class="total-tenants-text" v-bind:class="status.cssId" v-bind:for="status.cssId">
                                {{ status.totalTenants }}
                            </h1>
                            <h2 class="title-opt" v-bind:class="status.cssId" v-bind:for="status.cssId">
                                {{
                                    (formatStatusName(status.name).includes("Unemployed") ||
                                        formatStatusName(status.name).includes("in"))
                                    ? "Tenants"
                                    : "Tenants"
                                }}
                            </h2>
                        </div>
                        <div class="summary-bar-chart-container" v-if="linechartShow">
                            <bar-chart id="bar-chart" class="barChart" :chart-data="barChartData[status.name]"
                                :options="barchartOption"></bar-chart>
                        </div>
                    </div>
                    <div v-bind:class="status.cssId" v-bind:for="status.cssId" class="subtitle-opt-container">
                        <h2 class="subtitle-opt">
                            {{ formatNameInLineChart(status.name) }}
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BarChart from '../BarChart';
import { formatNameInLineChart, formatStatusName } from './helpers/checkboxTitleFormats';

export default {
    components: {
        BarChart
    },
    props: {
        status: {
            type: String
        },
        linechartShow: {
            type: Boolean
        },
        summaryBoxData: {
            type: Array
        },
        checkedItems: {
            type: Array
        },
        barChartData: {
            type: Object
        },
        barchartOption: {
            type: Object
        }
    },
    methods: {
        formatStatusName: (statusName) => formatStatusName(statusName),
        formatNameInLineChart: (statusName) => formatNameInLineChart(statusName)
    },
}
</script>

<style scoped>
.summary-text {
    color: #686868;
    width: 100%;
}

.summary-text h1 {
    margin: 0 0 0 0;
}

.v-text {
    color: #ffffff;
    margin: 2px 2px 2px 5px;
    width: 10px;
    height: 10px;
}

.box-checkbox-info {
    border: none;
    color: '#D8D8D8' !important;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
}

.total-tenants-text {
    display: inline;
    color: '#D8D8D8';
    font-family: Helvetica;
    font-size: 2rem;
    font-weight: 500;
}

.bar-container {
    margin-top: -20px;
}


.title-opt {
    display: inline;
    color: '#D8D8D8';
    font-family: 'Source Sans Pro';
    font-size: 2rem;
}

.subtitle-opt-container {
    text-align: left;
    border: none;
    color: '#D8D8D8';
}

.subtitle-opt {
    font-family: 'Source Sans Pro';
    font-size: 1.4rem;
    font-weight: 300;
    display: flex !important;
    align-items: center;
    margin: 0;
}
</style>