import { getStudentIctSchoolAvg, getTeacherIctSchoolAvg, getIctSchoolList } from './data-provider'
import { getGroupBarChartColorSheme, getLineChartColorScheme  } from './colour-scheme'
import dummyData from './dummy-GroupChartData.json'

import chartDataObjDefault from '../helpers/data-handler/chartDataObjDefault';
import { chartDataObjFunc } from '../helpers/data-handler/chartDataObj';

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min
}

// For Employment page
export function calcSum(tenants) {
  if (!tenants || tenants.length === 0) return 0; // Devuelve 0 si el arreglo es nulo o vacío
  const sum = tenants.reduce((prev, curr) => prev + curr, 0); // Calcula la suma
  return sum;
}

export function diffeQtyPeopleInYears(prevYearTenants, currYearTenants) {
  // Calcula la suma de inquilinos para el año anterior y el año actual
  const prevSum = calcSum(prevYearTenants);
  const currSum = calcSum(currYearTenants);

  if(prevSum === 0) {
    return null;
  }

  // Verifica si las sumas son NaN (por ejemplo, si prevYearTenants o currYearTenants son nulos)
  if (isNaN(prevSum) || isNaN(currSum)) return null;

  // Calcula la diferencia total entre las sumas
  const totalDifference = currSum - prevSum;
  return totalDifference;
}

export function compareDataByYear(prevYearTenants, currYearTenants) {
  const prevSum = calcSum(prevYearTenants);
  const currSum = calcSum(currYearTenants);

  if (isNaN(prevSum) || isNaN(currSum)) {
    return '-';
  }

  if (prevSum === 0) {
    return '-';
  }

  const growthRate = ((currSum / prevSum) - 1) * 100;

  if (!isFinite(growthRate)) {
    return '-';
  } else if (growthRate === 0) {
    return '0%';
  } else if (growthRate > 0) {
    return '+' + growthRate.toFixed(0) + '%';
  } else if (growthRate < 0) {
    return growthRate.toFixed(0) + '%';
  } else {
    return '-';
  }
}

export function getGroupChartData(){  
  return dummyData.data;
}

export function getLineChartData(tenants, extraData) {
  const totalTenants = tenants?.tenants.flatMap(el => Object.values(el))
  const sum = calcSum(totalTenants)
  const dataset = tenants?.tenants
  let chartDataObj = {}
  let labelsTenants = tenants?.labels

  if (!!extraData.selectedStatus && !!extraData.selectedFund && !!extraData.selectedDemo) {
    labelsTenants = [extraData.selectedStatus + ' | ' + tenants?.labels[0]]
  }

  if (sum === 0) {
    chartDataObj = chartDataObjDefault();
  } else {
    chartDataObj = chartDataObjFunc(dataset, labelsTenants, extraData);
  }
  return chartDataObj
}

export function getTableData(tabletype, currentYear, prevYear) {
  const tableLabels = currentYear?.labels
  const tableDataArray = []

  for (let labelIndex = 0; labelIndex < tableLabels?.length; labelIndex++) {
    const tenantsData = currentYear?.tenants[labelIndex]
    // console.log(tenantsData)
    const prevYearTenantsData = prevYear?.tenants[labelIndex]
    const total = calcSum(Object.values(tenantsData))
    const positives = currentYear?.positives[labelIndex]
    const positivesAvg = currentYear?.positivesAvg[labelIndex]
    let growthRate = ''

    if (prevYearTenantsData) {
      growthRate = compareDataByYear(Object.values(prevYearTenantsData), Object.values(tenantsData))
    } else {
      // growthRate = '+100%'
    }

    const tableDataSet = {
      type: tabletype,
      name: tableLabels[labelIndex],
      totalTenants: total,
      cssId: tableLabels[labelIndex].toLowerCase().replaceAll(' ', '-'),
      vForId: tableLabels[labelIndex],
      propId: tableLabels[labelIndex].replace(/\s+/g, ''),
      monthlyData: {
        tenants: Object.values(tenantsData),
        months: Object.keys(tenantsData)
      },
      positiveMoveOn: positives,
      positivesMoveOnAvg:positivesAvg,
      colorIndex: labelIndex
    }
    tableDataSet.monthlyData?.tenants.push(total)
    // tableDataSet.monthlyData.months.push('Total Tenants')
    // tableDataSet.monthlyData.months.push('Difference in 12 Months')
    if (tabletype === 'Topics') {
      tableDataSet.monthlyData?.tenants.push(getRandomInt(0, 100)) // radom?
    }
    
    tableDataArray.push(tableDataSet)
    tableDataSet.monthlyData?.tenants.push(growthRate)
  }
  return tableDataArray
}

export function getBarChartData(dataByCountry, checkboxArr) {
  const allDataDict = {}
  dataByCountry.forEach((el, index) => {
    let key = el.name
    let value = el.monthlyData?.tenants.slice(0, 12)
    allDataDict[key] = {
      labels: el.monthlyData.months.slice(0, 12),
      datasets: [{
        data: value,
        backgroundColor: `${ checkboxArr.includes( el.name ) ? getLineChartColorScheme(index) : '#D8D8D8' }`,
        hoverBackgroundColor: getLineChartColorScheme(index),
        hoverBorderColor: getLineChartColorScheme(index)
      }]
    }
  })
  return allDataDict
}

export function getStackedBarChartData(tenants, colorScheme, extraData) {

  const totalTenants = tenants?.tenants.flatMap(el => Object.values(el))
  const sum = calcSum(totalTenants)
  const barChartData = []

  var labels = tenants?.labels
  var labelsExacto = tenants?.labels

  if (((!!extraData.selectedStatus) == true)) {
    labels = [extraData.selectedStatus + ' | ' + 'All funds']
  }

  for (let i = 0; i < tenants?.labels.length; i++) {
    const barChartDataSet = {
      label: labels,
      babelExtra: labelsExacto[i],
      backgroundColor: colorScheme(i),
      data: Object.values(tenants?.tenants[i]),
      labels: Object.keys(tenants?.tenants[i]),
      barThickness: 20
    }
    barChartData.push(barChartDataSet)
  }


  let chartDataObj = {}

  if (sum === 0) {
    // the necessary values are these: label, backgroundColor, data
      const data = chartDataObjDefault();
      const dataLabels = data.labels;
      const filteredData = data.datasets.map(objeto => {
      const {  ...rest } = objeto;
      delete rest.borderColor;
      delete rest.pointRadius;
      delete rest.borderWidth;
      delete rest.pointBackgroundColor;
      delete rest.lineTension;
      return rest;
    });

    chartDataObj.labels = dataLabels;
    chartDataObj.datasets = filteredData;    
  } else if (tenants?.tenants.length > 0) {

    chartDataObj = {
      labels: Object.keys(tenants?.tenants[0]),
      datasets: barChartData
    }

  }

  return chartDataObj
}

export function getStackedBarChartDataDemo(tenants, colorScheme, extras) {
  const totalTenants = tenants?.tenants.flatMap(el => Object.values(el))
  const sum = calcSum(totalTenants)
  var labels = tenants?.labels
  if (!!extras.selectedStatus && !!extras.selectedFund && (!!extras.selectedDemo)) {
    labels = extras.selectedStatus + ' | ' + extras.selectedFund + ' | ' + extras.selectedDemo
  } else {
    labels = tenants?.labels
  }

  if(!tenants) return;
  const labels_decoded = Object.keys(tenants?.tenants[0]).map(item => decodeURIComponent(item))
  let labels_shorted = []
  if (extras.isNumeric == false) {
    labels_shorted = labels_decoded.map((lbl) => {
      if (lbl.length > 10) {
        return lbl.substring(0, 10) + "..."
      } else {
        return lbl
      }
    })
  } else {
    labels_shorted = labels_decoded
  }
  const barChartData = []
  //labels
  for (let i = 0; i < tenants?.labels.length; i++) {

    const barChartDataSet = {
      label: labels,
      labels:labels_shorted,
      babelExtra: tenants?.labels[i],
      backgroundColor: colorScheme(i),
      data: Object.values(tenants?.tenants[i]),
      barThickness: 15
    }
    if (Object.values(tenants?.tenants[i]).reduce((a, b) => a + b, 0)) {
      barChartData.push(barChartDataSet)
    }

  }

  let chartDataObj = {}
  if (sum === 0) {
    chartDataObj = {
      labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      datasets: [{
        label: 'No tenants data for this year',
        backgroundColor: 'transparent',
        data: []
      }]
    }
  } else if (tenants?.tenants.length > 0) {
    chartDataObj = {
      labels: labels_shorted,
      datasets: barChartData
    }
  }
  return chartDataObj
}

export function getGroupBarChartData (label, getInfoFunc, type) {

  const labelArr = label
  
  const baseYearData = {}
  const endYearData = {}

  labelArr.forEach(el => {
    baseYearData[el] = getInfoFunc(`${el}`, type, 'Base')
  })
  labelArr.forEach(el => {
    endYearData[el] = getInfoFunc(`${el}`, type, 'End')
  })

  const dataset = {
    labels: labelArr,
    datasets: [{ // label: 'baseYear',
      backgroundColor: getGroupBarChartColorSheme().opacity,
      barThickness: 15,
      data: Object.values(baseYearData)
    }, {// label: 'endYear',
      backgroundColor: getGroupBarChartColorSheme().normal,
      barThickness: 15,
      data: Object.values(endYearData)
    }]
  }

  return dataset
}

export function getIctTableData (getRateDataFunc, getAcrossAvgDataFunc) {
  const labelArr = getIctSchoolList()
  const totalBaseYearData = {}
  const totalEndYearData = {}
  const maleBaseYearData = {}
  const maleEndYearData = {}
  const femaleBaseYearData = {}
  const femaleEndYearData = {}
  const tableProp = {}

  labelArr.forEach(el => {
    totalBaseYearData[el] = getRateDataFunc(`${el}`, 'Total', 'Base')
    totalEndYearData[el] = getRateDataFunc(`${el}`, 'Total', 'End')
    maleBaseYearData[el] = getRateDataFunc(`${el}`, 'Male', 'Base')
    maleEndYearData[el] = getRateDataFunc(`${el}`, 'Male', 'End')
    femaleBaseYearData[el] = getRateDataFunc(`${el}`, 'Female', 'Base')
    femaleEndYearData[el] = getRateDataFunc(`${el}`, 'Female', 'End')
  })

  tableProp.columns = Object.keys(totalBaseYearData)
  tableProp.total = {
    beforeIns: Object.values(totalBaseYearData),
    afterIns: Object.values(totalEndYearData)
  }
  tableProp.male = {
    beforeIns: Object.values(maleBaseYearData),
    afterIns: Object.values(maleEndYearData)
  }
  tableProp.female = {
    beforeIns: Object.values(femaleBaseYearData),
    afterIns: Object.values(femaleEndYearData)
  }
  tableProp.total.beforeIns.push(getAcrossAvgDataFunc('Total', 'Base'))
  tableProp.total.afterIns.push(getAcrossAvgDataFunc('Total', 'End'))
  tableProp.total.difference = calcDifference(tableProp.total.beforeIns, tableProp.total.afterIns)
  tableProp.male.beforeIns.push(getAcrossAvgDataFunc('Male', 'Base'))
  tableProp.male.afterIns.push(getAcrossAvgDataFunc('Male', 'End'))
  tableProp.male.difference = calcDifference(tableProp.male.beforeIns, tableProp.male.afterIns)
  tableProp.female.beforeIns.push(getAcrossAvgDataFunc('Female', 'Base'))
  tableProp.female.afterIns.push(getAcrossAvgDataFunc('Female', 'End'))
  tableProp.female.difference = calcDifference(tableProp.female.beforeIns, tableProp.female.afterIns)
  return tableProp
}

export function calcDifference (base, end) {
  const difference = end.map((end, index) => {
    if (end - base[index] < 0) {
      const res = end - base[index]
      return res + '%'
    } else if (end - base[index] > 0) {
      const plus = '+'
      return plus.concat('', end - base[index]) + '%'
    } else {
      return 0 + '%'
    }
  })
  return difference
}

export function getStudentIctRate (school, type, year) {
  const avgSchoolIctSkill = getStudentIctSchoolAvg(`${school}`, `${type}`, `${year}`)
  return avgSchoolIctSkill
}

export function getTeacherIctRate (school, type, year) {
  const avgSchoolIctSkill = getTeacherIctSchoolAvg(`${school}`, `${type}`, `${year}`)
  return avgSchoolIctSkill
}


export function getMonthlyColumn() {
 const MONTH = ["0",
 "1-6",
 "6-12",
 "12-18",
 "18-24",
 "24-30",
 "30-36",
 "36-42",
 "42-48",
 "48-52",]
  //  const MONTH = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ]
  return MONTH
}
export function getMonthlyColumnNoDemo() {
  const MONTH = ['0', '6-12', '12-18', '18-24', '24-33', '33-36', '36-42', '42-48', '48-52', 'Oct', 'Nov', 'Dec']
  // const MONTH = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ]
  return MONTH
}

export function getFundColumn() {
  const FUNDS = [
    "RLPF1 - St Mungo’s",
    "RLPF2 - St Mungo’s",
    "NHPF 1 - Oxford",
    "NHPF 1 - Bristol",
    "NHPF 1 - Lancaster",
    "NHPF 1 - Manchester"]

  return FUNDS
}

export function getMonths() {
  return [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
}
