import { api } from "../api/stagingApi.js";

export async function getImpactByProjectId(idproject, { archived }) {
  try {
    const response = await api.get(`/impact/project`, {
      params: {
        idproject,
        archived,
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function getImpactByCompanyId(idcompany, { archived }) {
  try {
    const response = await api.get(`/impact/company`, {
      params: {
        idcompany,
        archived,
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function getImpactByGroupId({ archived }) {
  try {
    const response = await api.get(`/impact/group`, {
      params: {
        archived,
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function getImpact(idimpact) {
  try {
    const response = await api.get(`/impact/${idimpact}`);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function archiveImpact(idproject, archive) {
  try {
    const response = await api.post(`/impact/${idproject}`, {
      archive,
    });
    return response;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function getCurrentYears({ idcompany, idproject }) {
  try {
    const response = await api.get(`/files/getCurrentYears`, {
      params: {
        idProject: idproject,
        companyId: idcompany,
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function getImpactHeaders({ idcompany, idproject }) {
  try {
    const response = await api.post(`files/headers`, {
      idProcess: idproject,
      idCompany: idcompany,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function saveObjective({ idProcess, idCompany, pibotes, piboteCalculo }) {
  try {
    const response = await api.post(`/files/processV2`, {
      idProcess,
      idCompany,
      pibotes,
      piboteCalculo,
    });
    return response.data;
  } catch (error) {
    return {
      error: true,
      message: "An error has occurred",
      details: "",
    }; 
  }
}

export async function getAllHeadersOptions({ idCompany, idProcess, idImpact }) {
  try {
    const response = await api.post(`/files/headersNewStruct`, {
      idCompany, 
      idProcess, 
      idImpact
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function getAllFundsOptions({ idCompany, idProcess, idImpact }) {
  try {
    const response = await api.post(`/files/fundsHeadersNewStruct`, {
      idCompany, 
      idProcess, 
      idImpact
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function getImpactsDemo() {
  try {
    const response = await api.get(`/impact/demos`);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function updateDataPage(impactId, data) {
  try {
    const response = await api.post(`files/updateDataPage?idImpact=${impactId}`, data);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function deleteImpactById(idImpact) {
  try {
    const response = await api.delete(`/dashboard/impact/${idImpact}`);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function changepivotOrigin({ impactId, pivotOrigin }) {
  try {
    const response = await api.post(`/files/changepivotOrigin`, {
      impactId,
      pivotOrigin,
    });
    return response.data.response;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}
