import { api } from '@/api/stagingApi';
import buildLabelsAndTenants from '../buildLabelsAndTenants';
import { calcSum, getBarChartData } from '../../../data/data-handler';
import { diffeQtyPeopleInYears } from '../../../data/data-handler';
import { compareDataByYear } from '../../../data/data-handler';
import { getLineChartData } from '../../../data/data-handler';
import {
  getAllPurpleColor,
  getLineChartColorScheme,
} from '../../../data/colour-scheme';
import { getTableData } from '../../../data/data-handler';
import { filterTopics } from '../../../helpers/projectDetails/filterTopics';

export async function handleAllCaseDashboard(isDummyData = false) {
  const selectedStatus = this.selectedStatus;
  const selectedFund = this.selectedFund;
  const selectedDemo = this.selectedDemo;
  // const currentSelectedYear = this.selectedYear;
  // let prevSelectedYear = "";

  if (this.checkedItems.length > 0) {
    this.chartData = this.filterChartData(
      getLineChartData(this.tableTenants, {
        selectedStatus,
        selectedFund,
        selectedDemo,
        isNumeric: this.isNumeric,
      }),
      this.checkedItems
    );

    this.barChartData = getBarChartData(
      getTableData('Status', this.tableTenants, this.prevtableTenants),
      this.checkedItems
    );

    this.updateColors(this.viewMode, getLineChartColorScheme);
    return;
  }

  let tempImpactId = this.impactId;

  const params = {
    // For staging
    idImpact: isDummyData ? '6564dd3605b5f10011d424df' : tempImpactId,
    // For develop
    // idImpact: isDummyData ? '65368b52c2600b0018c7bda2' : tempImpactId,
    year: this.selectedYear ? this.selectedYear : '2022',
  };

  if (this.checkedItems.length === 0) {
    /**
     * This condition is triggered when you uncheck all the checkboxes 
     * (if there were any) and prevents making the initial request again
     */
    if((Object.keys(this.chartData).length !== 0) && isDummyData) {
      this.chartData = getLineChartData(this.previousTenants, getAllPurpleColor, {
        selectedStatus,
        selectedFund,
        selectedDemo,
        isNumeric: this.isNumeric,
      });
      this.barChartData = getBarChartData(
        getTableData('Status', this.tableTenants, this.prevtableTenants),
        this.checkedItems
      );
      this.updateColors(this.viewMode, getLineChartColorScheme);
      return;
    }
    
    this.preloader = true;
    const response = await api.get('/files/getDashboardV2', { params });
    params.year = '';
    const response2 = await api.get('/files/getDashboardV2', { params });

    const data = response.data.data.data.data.children;
    const year = this.selectedYear ? this.selectedYear : '2022';
    const builtResponse = buildLabelsAndTenants(data, year);
    const builtResponsePrevYear = buildLabelsAndTenants(data, year - 1);
    this.tableTenants = builtResponse;
    this.prevtableTenants = builtResponsePrevYear;

    // const valuesTenantsByYear = response.data.data.data.data.values; // aqui deberían de llegar varios años
    const valuesTenantsByYear2 = response2.data.data.data.data.values;

    // const tenantsCurrentYear = valuesTenantsByYear[year];
    // const tenantsPrevYear = valuesTenantsByYear[year - 1] || 0;

    const tenantsCurrentYear = valuesTenantsByYear2[year];
    const tenantsPrevYear = valuesTenantsByYear2[year - 1] || 0;

    const labels = ['All'];

    const tenants = { labels, tenants: [tenantsCurrentYear] };
    const prevYearTenants = { labels, tenants: [tenantsPrevYear] };

    this.totalTenants = calcSum(Object.values(tenants?.tenants[0]));
    this.totalDifferenceTenantsByYears = diffeQtyPeopleInYears(
      Object.values(prevYearTenants?.tenants[0]),
      Object.values(tenants?.tenants[0])
    );

    this.growthRate = compareDataByYear(
      Object.values(prevYearTenants.tenants[0]),
      Object.values(tenants.tenants[0])
    );

    this.previousTenants = tenants;
    this.chartData = getLineChartData(tenants, getAllPurpleColor, {
      selectedStatus,
      selectedFund,
      selectedDemo,
      isNumeric: this.isNumeric,
    });
  }

  this.barChartData = getBarChartData(
    getTableData('Status', this.tableTenants, this.prevtableTenants),
    this.checkedItems
  );

  this.tableData = getTableData(
    'Status',
    this.tableTenants,
    this.prevtableTenants
  );

  // right table
  this.summaryBoxData = filterTopics(
    getTableData('Status', this.tableTenants, this.prevtableTenants)
  );
  this.updateColors(this.viewMode, getLineChartColorScheme);

  this.homePivot = 0;
  if (this.tableTenants) {
    this.preloader = false;
    this.isLoadingCalcu = false;
  }
}
