import buildLabelsAndTenants from './buildLabelsAndTenants';
import getDashboardBySelections from './getDashboardBySelections';
// import getZeroData from "./getZeroData";

const caseThreeSelections = async (
  idImpact = '',
  status = '',
  fund = '',
  demo = '',
  year = ''
) => {
  const { data } = await getDashboardBySelections(
    idImpact,
    status,
    fund,
    demo,
    year
  );

  let response = '';
  if (status !== '' && fund !== '' && demo !== '') {
    // Case: Status -> Fund -> Demographics Selects
    response =
      data.data[0].data.data.children[status].children[fund].children[demo]
        .children;
  } else if (status === '' && fund !== '' && demo !== '') {
    // Case: Fund -> Demographics Selects (demo -> fund )
    response =
      data.data.data[0].data.data.children[fund].children[demo].children;
  } else if (status !== '' && fund === '' && demo === '') {
    // Case: Only Status
    response = data.data.data.data.children[status].children;
  } else if (status === '' && fund !== '' && demo === '') {
    // Case: Only Funds
    response = data.data.data[0].data.data.children[fund].children;
  } else {
    // Case: Status -> Demographics Selects
    response = data.data[0].data.data.children[status].children[demo].children;
  }

  const builtReponse = buildLabelsAndTenants(response, year);

  return builtReponse;
};

export default caseThreeSelections;
