const chartDataObjDefault = () => {
  let chartDataObj = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    datasets: [
      {
        label: 'No tenants data for this year',
        backgroundColor: 'transparent',
        borderColor: '#D8D8D8',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        pointRadius: 6,
        borderWidth: 1.5,
        pointBackgroundColor: '#FFFFFF',
        lineTension: 0,
      },
    ],
  };

  return chartDataObj;
};

export default chartDataObjDefault;
