import {
  getAllPurpleColor,
  getLineChartColorScheme,
  getTopicColorSchme,
} from '../../../data/colour-scheme';
import {
  calcSum,
  compareDataByYear,
  diffeQtyPeopleInYears,
  getBarChartData,
  getLineChartData,
  getTableData,
} from '../../../data/data-handler';
import buildLabelsAndTenants from '../buildLabelsAndTenants';
import caseThreeSelections from '../caseThreeSelections';
import { filterTopics } from '../../../helpers/projectDetails/filterTopics';
import getDashboardBySelections from '../getDashboardBySelections';

export async function handleFundsCaseDashboard() {
  let totalCurrTenants = this.totalCurrTenants;
  let totalPrevTenants = this.totalPrevTenants;
  let selectedStatus = this.selectedStatus;
  let selectedFund = this.selectedFund;
  let selectedDemo = this.selectedDemo;
  let displayingDataUpTo = this.displayingDataUpTo;

  if (this.selectedStatus && this.selectedFund && this.homePivot === 1) {
    // TODO: caso status -> Fund
    const status = this.selectedStatus;
    const fund = this.selectedFund;
    const idImpact = this.impactId;
    const year = this.selectedYear;

    if (this.checkedItems.length > 0) {
      this.chartData = this.filterChartData(
        getLineChartData(this.tenants, getLineChartColorScheme, {
          selectedStatus,
          selectedFund,
          selectedDemo,
          isNumeric: this.isNumeric,
        }),
        this.checkedItems
      );

      this.barChartData = getBarChartData(
        getTableData('Status', this.tenants, this.prevYearTenants),
        this.checkedItems
      );

      this.updateColors(this.viewMode, getLineChartColorScheme);
      return;
    }

    this.preloader = true;
    const data = await getDashboardBySelections(
      idImpact,
      status,
      fund,
      '',
      year
    );
    const dataTenants =
      data.data.data.data[0].data.data.children[status].children[fund].values[
        year
      ];
    const positives = data.data.data.data[0].data.data.children[status].children[fund].values[`${year}-moveOn`]
    const positivesMoveOn = data.data.data.data[0].data.data.children[status].children[fund].values[`${year}-moveOnAvg`]
    this.tenants = { labels: [fund], tenants: [dataTenants], positives: [positives], positivesAvg:[positivesMoveOn] };
    let tenants = this.tenants;

    if (displayingDataUpTo.length > 1) {
      const dataTenants2 =
        data.data.data.data[0].data.data.children[status].children[fund].values[
          year - 1
        ] || 0;

      this.prevYearTenants = { labels: [fund], tenants: [dataTenants2] };
      let prevYearTenants = this.prevYearTenants;
      totalPrevTenants = prevYearTenants.tenants.flatMap((el) =>
        Object.values(el)
      );
    }
    this.totalTenants = calcSum(Object.values(tenants.tenants[0]));

    totalCurrTenants = tenants.tenants.flatMap((el) => Object.values(el));

    this.totalDifferenceTenantsByYears = diffeQtyPeopleInYears(
      totalPrevTenants,
      totalCurrTenants
    );

    this.growthRate = compareDataByYear(totalPrevTenants, totalCurrTenants);

    this.barChartData = getBarChartData(
      getTableData('Status', tenants, this.prevYearTenants),
      this.checkedItems
    );

    // Tabla de abajo del dashboard
    this.tableDataByStatus = getTableData('Status', tenants);
    // Gráfico lineal, no carga bien los puntos si se quita
    this.chartData = this.filterChartData(
      getLineChartData(tenants, getLineChartColorScheme, {
        selectedStatus,
        selectedFund,
        selectedDemo,
        isNumeric: this.isNumeric,
      }),
      this.checkedItems
    );

    // Checkboxs DERECHA
    this.summaryBoxData = filterTopics(
      getTableData('Status', tenants, this.prevYearTenants)
    );
    this.preloader = false;
    this.updateColors(this.viewMode, getTopicColorSchme);
    // TODO: FIN HERE
  } else if (
    this.selectedStatus === null &&
    this.selectedFund !== null &&
    this.selectedFund !== 'Show all' &&
    this.selectedFund &&
    this.selectedDemo === null
  ) {
    // TODO: ONLY FUNDS
    const fund = this.selectedFund;
    const idImpact = this.impactId;
    const year = this.selectedYear;

    if (this.checkedItems.length > 0) {
      this.chartData = this.filterChartData(
        getLineChartData(this.tableTenants, getLineChartColorScheme, {
          selectedStatus,
          selectedFund,
          selectedDemo,
          isNumeric: this.isNumeric,
        }),
        this.checkedItems
      );
      this.updateColors(this.viewMode, getLineChartColorScheme);
      return;
    }

    if (this.isSelectedCheckboxInViewAll) {
      this.checkedItems = [];
      this.isSelectedCheckboxInViewAll = false;
    }
    this.preloader = true;
    const { data } = await getDashboardBySelections(
      idImpact,
      '',
      fund,
      '',
      year
    );

    const dataFund = data.data.data[0].data.fundData[fund].values[year];
    const response = data.data.data[0].data.data.children[fund].children;
    const dataTenants = buildLabelsAndTenants(response, year);
 
    let dataFundPrevYear = 0;

    // Correcting the check for the previous year's data
    const previousYear = year - 1;
    const previousYearObject = displayingDataUpTo.find(item => item.year === previousYear.toString());

    if (previousYearObject) {
      // If the previous year's data is found in displayingDataUpTo, retrieve the data
      const dataTenantsOneYearAgo = await caseThreeSelections(idImpact, '', fund, '', previousYear);
      this.prevYearTenants = dataTenantsOneYearAgo;
      const fundData = data.data.data[0].data.fundData;
      if (fundData && fundData[fund] && fundData[fund].values && fundData[fund].values[previousYear]) {
        dataFundPrevYear = fundData[fund].values[previousYear];
      }
    } else {
      // console.log('The year before', year, 'is not present in displayingDataUpTo.');
    }

    this.tableTenants = dataTenants;
    let tableTenants = this.tableTenants;

    const labels = ['All'];

    this.tenants = { labels, tenants: [dataFund] };
    let tenants = this.tenants;
    let tenantsPrevYear = { labels, tenants: [dataFundPrevYear] };
    this.prevYearTenants = tenantsPrevYear;
    let prevYearTenants = this.prevYearTenants;

    const tableTenantsTem = tableTenants;

    var tem = 0;

    tableTenantsTem.tenants.map((tenant) => {
      tem = calcSum(Object.values(tenant)) + tem;
    });

    this.totalTenants = tem;

    this.totalDifferenceTenantsByYears = diffeQtyPeopleInYears(
      Object.values(prevYearTenants.tenants[0]),
      Object.values(tenants.tenants[0])
    );

    this.growthRate = compareDataByYear(
      Object.values(prevYearTenants.tenants[0]),
      Object.values(tenants.tenants[0])
    );

    this.barChartData = getBarChartData(
      getTableData('Status', tableTenants, prevYearTenants),
      this.checkedItems
    );

    // Tabla calculos abajo
    this.tableDataByStatus = getTableData(
      'Status',
      tableTenants,
      prevYearTenants
    );

    // checkbox derecha
    this.summaryBoxData = filterTopics(
      getTableData('Status', tableTenants, prevYearTenants)
    );

    // MUESTRA Y CAMBIA DE COLOR (CLICK CHECKBOXS)

    if (this.checkedItems.length === 0) {
      this.chartData = getLineChartData(tenants, getAllPurpleColor, {
        selectedStatus,
        selectedFund,
        selectedDemo,
        isNumeric: this.isNumeric,
      });
    }

    this.preloader = false;
    this.updateColors(this.viewMode, getLineChartColorScheme);
  }
}
