<template>
    <div>
        <div class="d-flex flex-column align-items-start">
            <h3 v-if="!isCreatingView" class="y-axes">{{ localYAxesValue || 'No of tenancies' }}</h3>
            <h3 v-else class="y-axes" v-show="showHeader" @dblclick="toggleHeader">{{ localYAxesValue || 'No of tenancies' }}</h3>
            <template v-if="isCreatingView">
                <input v-if="!showHeader" ref="unitMeasurementRef" class="tenantsValue" v-model="localYAxesValue" @input="onInput" />
                <p class="data-time-period-description">Your Y axis shows the unit you’re measuring. Double Click to edit the wording here.</p>
            </template>
        </div>
        <div v-if="!showGroupChartData" class="graph-line-container">
            <line-chart
            :chart-data="chartData"
            :options="ChartOptions"
            v-if="linechartShow"
        ></line-chart>
        </div>
        <div v-else>
            <group-bar-chart
            :chart-data="chartData"
            :options="ChartOptions"
            v-if="false"
            ></group-bar-chart>
        </div>
        <!-- {{ stackedBarChartData }} -->
        <stacked-bar-chart
            id="stacked-bar-chart"
            :chart-data="stackedBarChartData"
            :options="stackedBarchartOption"
            v-if="stackedChartShow === true"
        ></stacked-bar-chart>
    </div>
</template>

<script>
import LineChart from "../components/Chart/LineChart.js";
import GroupBarChart from "../components/Chart/GroupBarChart.js";
import StackedBarChart from "../components/Chart/StackedBarChart.js";

export default {
    props: {
        showGroupChartData: {
            type: Boolean
        },
        linechartShow: {
            type: Boolean
        },
        ChartOptions: {
            type: Object
        },
        chartData: {
            type: Object
        },
        stackedBarChartData: {
            type: Object
        },
        stackedBarchartOption: {
            type: Object
        },
        stackedChartShow: {
            type: Boolean
        },
        yAxesValue: {
            type: String
        },
        isCreatingView: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            localYAxesValue: this.yAxesValue,
            inputEnabled: false,
            showHeader: true,
        }
    },
    components: {
        LineChart,
        GroupBarChart,
        StackedBarChart
    },
    methods: {
        enableInput() {
            this.inputEnabled = true;
        },
        toggleHeader() {
            this.showHeader = !this.showHeader;
        },
        onInput() {
            this.$emit('y-axes-value-changed', this.localYAxesValue);
        }
    },
    watch: {
        yAxes(newValue) {
            this.localYAxesValue = newValue;
        }
    },
}
</script>

<style>
.tenantsValue {
    background-color: inherit;
    border-bottom: 1px solid #8954BA!important;
    border: none;
    color: var(--color-purple);
    font-size: 2rem;
    width: 100px;
}

.y-axes {
    color: var(--color-purple);
    font-size: 2rem !important;
    margin: unset !important;
}

</style>