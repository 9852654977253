import { getTopicColorSchme } from '../../../data/colour-scheme';
import {
  calcSum,
  compareDataByYear,
  diffeQtyPeopleInYears,
  getTableData,
} from '../../../data/data-handler';
import buildLabelsAndTenants from '../buildLabelsAndTenants';
import caseThreeSelections from '../caseThreeSelections';
import { api } from '../../../api/stagingApi';
import { processCheckedItemsDemos } from '../colors/processCheckedItems';
import { filterTopics } from '../../../helpers/projectDetails/filterTopics';

export async function handleDemographicsCaseDashboard() {
  let totalCurrTenants = this.totalCurrTenants;
  let totalPrevTenants = this.totalPrevTenants;
  let SchartData = this.SchartData;
  let displayingDataUpTo = this.displayingDataUpTo;

  // CASE: Only demographics
  if (
    ((this.selectedStatus === null || this.selectedStatus === 'Show all') &&
      this.selectedFund === null) ||
    this.selectedFund === 'Show all'
  ) {
    if (this.checkedItems.length > 0) {
      processCheckedItemsDemos.call(this);
      return;
    }
  }
  // CASE: status -> fund
  if (
    (this.selectedStatus &&
      this.selectedStatus !== 'Show all' &&
      this.selectedDemo &&
      this.selectedDemo !== 'Select option' &&
      this.homePivot === 1 &&
      this.selectedFund === null) ||
    this.selectedFund === 'Show all'
  ) {
    if (this.selectedFund === 'Show all') return;
    if (this.checkedItems.length > 0) {
      processCheckedItemsDemos.call(this);
      return;
    }
    const idImpact = this.impactId;
    const status = this.selectedStatus;
    const demo = this.selectedDemo;
    const year = this.selectedYear;

    let dataPrevYear = null;
    this.preloader = true;

    const prevYearExists = displayingDataUpTo.some(
      (item) => item.year === (year - 1).toString()
    );
 
    if (prevYearExists) {
      dataPrevYear = await caseThreeSelections(
        idImpact,
        status,
        '',
        demo,
        year - 1
      );
      this.prevYearTenants = dataPrevYear;
    }

    const data = await caseThreeSelections(idImpact, status, '', demo, year);
    this.tenants = data;
    this.stackedBarChartData = SchartData;
    this.preloader = false;
  }

  // CASE: demo <-> funds
  if (this.selectedFund != null && this.selectedDemo != null) {
    if (this.checkedItems.length > 0) {
      processCheckedItemsDemos.call(this);
      return;
    }
  }

  // CASE: Three selects
  if (
    this.selectedStatus &&
    this.selectedFund &&
    this.selectedYear &&
    this.selectedFund !== 'Show all'
  ) {
    this.preloader = true;
    const status = this.selectedStatus;
    const fund = this.selectedFund;
    const demo = this.selectedDemo;
    const idImpact = this.impactId;
    const year = this.selectedYear;

    const data = await caseThreeSelections(idImpact, status, fund, demo, year);
    if (displayingDataUpTo.length > 1) {
      const dataPrevYear = await caseThreeSelections(
        idImpact,
        status,
        fund,
        demo,
        year - 1
      );
      this.prevYearTenants = dataPrevYear;
    }
    this.tenants = data;
  } else if (
    // Case: Only Demographics
    ((this.selectedStatus === null || this.selectedStatus === 'Show all') &&
      this.selectedFund === null) ||
    this.selectedFund === 'Show all'
  ) {
    try {
      const demo = this.selectedDemo;
      const idImpact = this.impactId;
      const year = this.selectedYear;

      if (this.isSelectedCheckboxInViewAll) {
        this.checkedItems = [];
        this.isSelectedCheckboxInViewAll = false;
      }
      this.preloader = true;
      const data = await api.get(
        `/files/getDashByDemografiaV2?idImpact=${idImpact}&demography=${demo}`
      );
      let response = data.data.data[0].data.data.children[demo].children;
      this.tenants = buildLabelsAndTenants(response, year);
      this.prevYearTenants = buildLabelsAndTenants(response, year - 1);
    } catch (error) {
      console.log(error);
    }
  } else if (this.selectedFund != null && this.selectedDemo != null) {
    const fund = this.selectedFund;
    const demo = this.selectedDemo;
    const idImpact = this.impactId;
    const year = this.selectedYear;
    this.preloader = true;
    // case fund -> demo (demo -> status)
    const data = await caseThreeSelections(idImpact, '', fund, demo, year);
    if (displayingDataUpTo.length > 1) {
      const dataPrevYear = await caseThreeSelections(
        idImpact,
        '',
        fund,
        demo,
        year - 1
      );
      this.prevYearTenants = dataPrevYear;
    }
    this.tenants = data;
  }

  let tenants = this.tenants;
  let prevYearTenants = this.prevYearTenants;
  totalCurrTenants = tenants?.tenants.flatMap((el) => Object.values(el));
  totalPrevTenants = prevYearTenants?.tenants.flatMap((el) =>
    Object.values(el)
  );
  this.totalTenants = calcSum(totalCurrTenants);

  this.totalDifferenceTenantsByYears = diffeQtyPeopleInYears(
    totalPrevTenants,
    totalCurrTenants
  );

  this.growthRate = compareDataByYear(totalPrevTenants, totalCurrTenants);

  processCheckedItemsDemos.call(this);

  this.TopicTableData = this.filterTableDemo(
    getTableData('Topics', tenants, prevYearTenants),
    this.stackedBarChartData
  );

  // right table
  this.summaryBoxData = filterTopics(
    getTableData('Topics', tenants, prevYearTenants)
  );

  this.updateColors(this.viewMode, getTopicColorSchme);
  this.preloader = false;
}
