export function updateColorsInView(view, colorScheme) {
  const tableFontDomIndex = view === 'Demo' ? 7 : 9;

  this.summaryBoxData.forEach((box) => {
    const isChecked = this.checkedItems.includes(box.name);
    const checkedColor = isChecked ? colorScheme(box.colorIndex) : '#686868';
    const tableColor = isChecked ? checkedColor : '#212529';

    const cssId = box.cssId;
    const elements = this.$el.getElementsByClassName(`${cssId}`);

    if (elements.length > 1 && elements[2]) {
      elements[1].style.color = checkedColor; // etiqueta
      elements[2].style.border = `1px solid ${checkedColor}`; // div conectado al checkbox
      elements[3].style.color = isChecked ? checkedColor : '#ffffff'; // V
      elements[4].style.color = checkedColor; // texto del tema

      if (elements[tableFontDomIndex]) {
        elements[tableFontDomIndex].style.color = tableColor; // nombre de la tabla
      }
    }
  });
}
